export function openWindowPopup(windowOptions: {
  url: string
  target: string
  features?: string
}) {
  const features = windowOptions.features || 'popup,width=500,height=550'
  try {
    const newWindow = window.open(
      windowOptions.url,
      windowOptions.target,
      features,
    )

    return newWindow
  } catch (_) {
    return null
  }
}
