import { openWindowPopup } from '~/utils/OpenWindowPopup'

import { authTokenLocalStorageKey } from '~/types/Auth'

import type { SsoProvider } from '~/types/Auth'

export function useStartSso() {
  const runtimeConfig = useRuntimeConfig()
  const intervalHandler = ref<null | number>(null)

  onBeforeUnmount(() => {
    if (intervalHandler.value) clearInterval(intervalHandler.value)
  })

  return {
    /**
     * Start the sso authentication process.
     *
     * @param runtimeConfig - Nuxt runtime configuration to extract the base URL from.
     * @param provider - Any of our supported providers (google, facebook, soundcloud...).
     * @param callback - A callback function with the SSO code to send to the auth service.
     * @param timer - the interval rate in ms for every code check.
     */
    authStartSso: function (
      provider: SsoProvider,
      callback: (code: string) => void,
      timer = 100,
    ) {
      if (!process.client) throw new Error('This function is client side only')

      if (intervalHandler.value) clearInterval(intervalHandler.value)

      window.localStorage.removeItem(authTokenLocalStorageKey)
      openWindowPopup({
        url: `${runtimeConfig.public.AUTH_BASE_URL}/api/sso/${provider}/start/?next=${window.origin}/finalize-auth/`,
        target: `groover-${provider}-authentification`,
      })

      intervalHandler.value = window.setInterval(() => {
        const code = window.localStorage.getItem(authTokenLocalStorageKey)

        if (!code) return

        if (intervalHandler.value) clearInterval(intervalHandler.value)

        intervalHandler.value = null
        callback(code)
      }, timer)
    },
  }
}
